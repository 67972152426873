@charset "utf-8";

$primary: #f40028;
$primary-dark: #db0023;
$primary-text: #353535;

$success: #008734;
// Update Bulma's global variables
$family-sans-serif: -apple-system, BlinkMacSystemFont, "Myriad Pro", "Josefin Sans", Arial, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$grey-darker: #444444;

$input-hover-border-color: $primary;
$input-focus-border-color: $primary-dark;
$input-arrow: $primary;
$input-focus-box-shadow-color: transparent;
$input-focus-box-shadow-size: 0;
$input-shadow: none;

$body-family: $family-sans-serif;
$button-padding-vertical: 20px;
$notification-padding: 0.5rem 1rem 0.5rem 1rem;


// Import only what you need from Bulma
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/form/_all.sass";

@import "../../../node_modules/bulma/sass/elements/button.sass";
@import "../../../node_modules/bulma/sass/elements/container.sass";
@import "../../../node_modules/bulma/sass/elements/box.sass";
@import "../../../node_modules/bulma/sass/elements/title.sass";
@import "../../../node_modules/bulma/sass/elements/notification";
@import "../../../node_modules/bulma/sass/elements/tag";
@import "../../../node_modules/bulma/sass/elements/other";

@import "../../../node_modules/bulma/sass/helpers/visibility";
@import "../../../node_modules/bulma/sass/helpers/spacing";

@import "../../../node_modules/bulma/sass/grid/columns";
@import "../../../node_modules/bulma/sass/grid/tiles";


@import "../../../node_modules/bulma/sass/layout/hero.sass";
@import "../../../node_modules/bulma/sass/layout/section.sass";

@import "../../../node_modules/bulma/sass/components/navbar.sass";
@import "../../../node_modules/bulma/sass/components/card";
@import "../../../node_modules/bulma/sass/components/modal";
@import "../../../node_modules/bulma/sass/components/level";
@import "../../../node_modules/bulma/sass/components/tabs";
@import "~bulma-badge/src/sass/index";
@import "~bulma-tooltip/src/sass/index";
