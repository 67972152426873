.tippy-popper {
    max-width: unset;
}

.navbar {
    max-width: 100vw;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
}

.navbar a {
    text-decoration: none;
}

.navbar .submenu {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
    padding: 0.5rem;
    z-index: 20;
    top: 62px;
}

.navbar .hover-enabled li:hover .submenu {
    height: auto;
    width: auto;
    overflow: auto;
    visibility: visible;
    z-index: 20;
}

.navbar .promo-imglink img {
    visibility: visible;
    height: 100px;
    width: 100px;
    left: -1000px;
}

.navbar .hover-enabled li:hover .submenu .promo-imglink img,
.navbar .nav-show .promo-imglink img {
    visibility: visible;
    height: auto;
    width: auto;
    left: 0;
}

.navbar .nav-toggle {
    height: 0;
    width: 0;
    overflow: hidden;
}

.navbar ul.menu {
    display: flex;
    justify-content: center;
    position: relative;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar ul li {
    display: flex;
    align-items: center;
}

.navbar a {
    display: block;
    color: inherit;
    text-decoration: none;
}

.navbar a.nav-toplink {
    line-height: 2.75rem;
    padding: 0 1.25rem;
    margin: 0.3125rem 0;
    min-height: 44px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: all 0.25s ease-in-out;
}

.navbar a.nav-toplink .sub-icon {
    padding-left: 0.3125rem;
}

.navbar .submenu a {
    padding: 0 0.5rem;
}

.navbar a.promo-imglink {
    padding: 0;
    width: 100%;
}

.navbar a.promo-imglink:hover,
.navbar img:hover {
    opacity: 0.8;
}

.navbar a.promo-imglink,
.navbar img {
    transition: opacity 0.3s linear;
}

.navbar .promo-container {
    min-width: 50px;
}

.navbar .icon {
    margin-right: 0.75rem;
}

.navbar .submenu-flex {
    flex: 1;
    max-width: 100%;
    margin: 0 0.5rem;
}

.navbar .submenu-flex .submenu-title > a {
    padding: 0.625rem 0 0.3125rem;
}

.navbar .submenu-flex .submenu-title {
    font-weight: bold;
    margin: 0 0 0.625rem;
    border-bottom: 1px solid #d8d8d8;
}

.navbar .submenu {
    box-shadow: 0px 6px 9px rgba(0, 0, 0, .15);
}

.navbar ul.menu > li:hover .nav-toplink,
.navbar ul.menu > li .nav-toplink.active:not(:last-child) {
    border-bottom: 1px solid lightgrey;
    text-decoration: none;
}

.navbar .submenu .sub-title {
    border-bottom: 1px solid lightgrey;
    font-weight: bold;
}

.navbar .nav-show .nav-toggle-close svg {

    height: 1rem;
    width: 1rem;
}


.navbar {
    color: #232323;
    fill: #232323;
}

.navbar .link--parent {
    flex: 1 0 150px;
    text-align: left;
    max-width: 100%;
    margin: 0 0.5rem;
}

.navbar .nav-toplink {
    display: flex;
    text-transform: uppercase;
    letter-spacing: 0.18rem;
    font-size: 0.875rem;
}

.navbar a,
.navbar a .sub-icon {
    text-transform: none;
    letter-spacing: normal;
}

.navbar a:hover,
.navbar a:hover .sub-icon {
    text-decoration: underline;
    fill: #232323;
}

.navbar .view-all {
    font-style: italic;
    font-weight: bold;
}

.navbar .cta a:hover {
    text-decoration: none;
}
